import { MouseEvent } from 'react';
import { useTheme } from 'src/ui-kit/theme';
import { Bundle, Unit } from '../../api/types';
import { defaultProductImage } from '../../fixtures';
import { useLocalize } from '../../services/localize/useLocalize';
import formatWeight from '../../utils/products/formatWeight';
import PriceRange from '../PricesRange/PricesRange';
import { ProductRowStyled } from './ProductRow.styles';

export interface ProductRowProps {
  imagesSrc: string | undefined;
  title: string;
  unit: Unit;
  weight: number | null;
  volume: number | null;
  priceFrom: number;
  priceTo: number;
  bundle: Bundle;
  currency: string;
  itemInStock: boolean;
  onClick?: (event: MouseEvent) => void;
}

const ProductRow = (props: ProductRowProps) => {
  const {
    imagesSrc,
    title,
    unit,
    weight,
    volume,
    priceFrom,
    priceTo,
    currency,
    itemInStock,
    onClick,
  } = props;

  const theme = useTheme();
  const localize = useLocalize();

  const viewWeight = formatWeight(unit, weight, volume, localize);

  return (
    <ProductRowStyled
      theme={theme}
      className='ProductRow'
      data-marker='Product Card'
      onClick={onClick}
    >
      <div className='ProductRow__imageWrap'>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          className='ProductRow__image'
          data-marker='Product Photo'
          src={imagesSrc || defaultProductImage}
          alt={title}
        />
      </div>

      <div className='ProductRow__info'>
        <div className='ProductRow__description'>
          <div
            className='ProductRow__title'
            title={title}
          >
            {title}
          </div>
          <span
            className='ProductRow__infoWeight'
            data-marker='Weight'
          >
            {
              unit === 'kg'
                ? localize('general_one_kg')
                : viewWeight
            }
          </span>
        </div>

        <div className='ProductRow__priceAndButton'>
          {itemInStock ? (
            <div className='ProductRow__price'>
              <PriceRange
                currency={currency}
                startPrice={priceFrom}
                endPrice={priceTo}
                variant="small"
              />
            </div>
          ) : (
            <div className='ProductRow__unavailable' data-marker='Product unavailable'>
              <span>{localize('products.unavailable')}</span>
            </div>
          )}
        </div>
      </div>
    </ProductRowStyled>
  );
};

export default ProductRow;
