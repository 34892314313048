import cn from 'classnames';
import { forwardRef, InputHTMLAttributes, RefObject } from "react";
import { IS_IOS } from "src/data/constants";
import { useTheme } from "src/ui-kit/theme";
import { SearchInputStyled } from './SearchInput.styles';

export type SearchBoxViewVariant = 'mobile' | 'mainPage' | 'headerDesktop';

export interface SearchInputBaseProps extends InputHTMLAttributes<HTMLInputElement> {
  ref: RefObject<HTMLInputElement>;
  value: string;
}

export type SearchInputProps = SearchInputBaseProps & { variant: SearchBoxViewVariant }

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>((props, ref) => {
  const { variant, ...restProps } = props;
  const theme = useTheme();

  const specificAttributes: InputHTMLAttributes<HTMLInputElement> = {};
  if (IS_IOS) specificAttributes.inputMode = 'search';

  return (
    <SearchInputStyled
      theme={theme}
      {...restProps}
      {...specificAttributes}
      ref={ref}
      type="search"
      data-marker="Search Input"
      data-testid="searchBoxInput"
      autoFocus={variant === 'mobile'}
      className={cn('SearchInput', {
        'SearchInput_withMobile': variant === 'mobile',
      })}
    />
  );
});

SearchInput.displayName = 'SearchInput';

export default SearchInput;
