import Image from "next/image";
import useLocalize from "src/services/localize/useLocalize";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { Typography } from "src/ui-kit/Typography";

interface SearchResultsNotFoundProps {
  searchString: string;
}

const SearchResultsNotFound = (props: SearchResultsNotFoundProps) => {
  const localize = useLocalize();
  const { searchString } = props;

  return (
    <StylishBox
      data-marker="No Results"
      sbs={{
        height: '225px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        p: '10px',
        textAlign: 'center',
      }}
    >
      <Image
        width={150}
        height={67}
        src="/static/images/donut.png"
        alt="Donut"
      />
      <Typography
        variant="text6"
        sbs={{
          mt: '25px',
          lineHeight: '17px',
        }}
      >
        {localize('search.result.nothing-found')}
        <strong>{` «${searchString}»`}</strong>
      </Typography>
    </StylishBox>
  );
};

export default SearchResultsNotFound;
