import useLocalize from "src/services/localize/useLocalize";
import { SearchInputBaseProps } from "../components/SearchInput/SearchInput";
import { SearchPlace, sendOpenSearch } from "../helpers/analytics";
import { ChangeEvent, KeyboardEventHandler, RefObject } from "react";
import { disableBodyScroll } from "src/utils/system/scrollLock";

interface Params {
  inputRef: RefObject<HTMLInputElement>;
  searchPlace: SearchPlace;
  inputValue: string;
  searchIsOpened: boolean;
  openSearchPage(): void;
  openSearch(): void;
  setSearchString(value: string): void;
  setInputValue(value: string): void;
}

const useSearchInputProps = (params: Params): SearchInputBaseProps => {
  const {
    inputRef,
    searchPlace,
    inputValue,
    searchIsOpened,
    setInputValue,
    setSearchString,
    openSearchPage,
    openSearch,
  } = params;

  const localize = useLocalize();

  const handleInputFocus = () => {
    sendOpenSearch(searchPlace);

    if (!searchIsOpened) {
      openSearch();
      disableBodyScroll();
    }

    setSearchString(inputValue);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchString = event.target.value;

    setInputValue(searchString);
    setSearchString(searchString);
  };

  const handleInputClick = () => {
    if (!inputValue) {
      openSearch();
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = event => {
    if (event.key === 'Enter') {
      openSearchPage();
    }
  };

  const searchInputProps: SearchInputBaseProps = {
    ref: inputRef,
    placeholder: localize('search_box_placeholder'),
    value: inputValue,
    onChange: handleInputChange,
    onFocus: handleInputFocus,
    onClick: handleInputClick,
    onKeyDown: handleKeyDown,
    maxLength: 100,
  };

  return searchInputProps;
};

export default useSearchInputProps;
