import { Unit } from '../../api/types';
import { LocalizeFunc } from '../../services/localize/useLocalizeService';

const formatWeight = (unit: Unit, weight: number | null, volume: number | null, localize: LocalizeFunc) => {
  if (unit === 'kg') {
    return localize('measure_per_one_kg');
  } else if (unit === 'pcs') {
    if (volume) {
      return volume < 1000
        ? `${volume} ${localize('measure_ml')}`
        : `${volume / 1000} ${localize('measure_liter')}`;
    }
    if (weight) {
      return weight < 1000
        ? `${weight} ${localize('measure_gram')}`
        : `${weight / 1000} ${localize('measure_kg')}`;
    }
    return null;
  }
};

export default formatWeight;
