import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef, useState } from "react";
import { MIN_SEARCH_LENGTH } from 'src/data/constants';
import useLang from "src/services/language/useLang";
import { enableBodyScroll, isBodyScrollDisabled } from 'src/utils/system/scrollLock';
import { ClearIconProps, SearchBoxProps } from '../components/SearchBox/SearchBox';
import { SearchPlace } from '../helpers/analytics';
import useSearch from "./useSearch";
import { useSearchAnalytics } from './useSearchAnalytics';
import useSearchInputProps from './useSearchInputProps';
import useSearchResultsWrapperProps from './useSearchResultsWrapperProps';
import { useRouter, useSearchParams } from 'next/navigation';

interface UseSearchBoxLogicResult extends Omit<SearchBoxProps, 'variant'> {
  openSearch(): void;
}

const useSearchBoxLogic = (place: SearchPlace): UseSearchBoxLogicResult => {
  const lang = useLang();
  const searchParams = useSearchParams();
  const router = useRouter();
  const [searchIsOpened, setSearchIsOpened] = useState(false);
  const qSearchParam = searchParams?.get('q');
  const initialInputValue = qSearchParam || '';
  const [searchString, setSearchString] = useState('');
  const [inputValue, setInputValue] = useState(initialInputValue);
  const inputRef = useRef<HTMLInputElement>(null);

  const setSearchStringDebounced = useMemo(() => debounce(setSearchString, 400), []);
  const searchInputIsFilled = inputValue.length >= MIN_SEARCH_LENGTH;

  const searchData = useSearch({ searchValue: searchString, language: lang });

  useEffect(() => {
    return () => {
      // Check if body scroll is currently disabled
      if (isBodyScrollDisabled()) {
        // If scroll is disabled, enable it back on unmount
        enableBodyScroll();
      }
    };
  }, []);

  useSearchAnalytics({
    place,
    searchData,
    searchString,
  });

  const openSearch = () => {
    inputRef.current?.focus();
    setSearchIsOpened(true);
  };

  const openSearchPage = () => {
    if (inputValue && searchInputIsFilled) {
      closeSearch();
      const encodedInputValue = encodeURIComponent(inputValue);
      const href = `/${lang}/search/?q=${encodedInputValue}`;
      router.push(href);
    }
  };

  const searchInputProps = useSearchInputProps({
    inputRef,
    inputValue,
    searchIsOpened,
    searchPlace: place,
    setSearchString: setSearchStringDebounced,
    openSearch,
    openSearchPage,
    setInputValue,
  });

  const clearSearchState = () => {
    setInputValue('');
    setSearchStringDebounced('');
  };

  const closeSearch = () => {
    setSearchIsOpened(false);
    enableBodyScroll();

    if (!qSearchParam) {
      setInputValue(initialInputValue);
    }
  };

  const clearSearch = () => {
    inputRef.current?.focus();
    clearSearchState();
  };

  const searchResultsWrapperProps = useSearchResultsWrapperProps({
    searchInputIsFilled,
    searchIsOpened,
    searchString,
    searchData,
    pageForAnalytics: place,
    closeSearch,
    openSearchPage,
  });

  const clearIconProps: ClearIconProps = {
    onClick: clearSearch,
    isVisible: inputValue.length > 0,
  };

  return {
    searchInputProps,
    searchIsOpened,
    searchResultsWrapperProps,
    clearIconProps,

    closeSearch,
    openSearch,
    onLoupeIconClick: openSearchPage,
  };
};

export default useSearchBoxLogic;
