import { useEffect } from "react";
import { SearchPlace, sendSearchFull, sendSearchStart } from "../helpers/analytics";
import { MIN_SEARCH_LENGTH } from "src/data/constants";
import { SearchData } from "./useSearch";

interface SearchAnalyticsParams {
  place: SearchPlace;
  searchData: SearchData;
  searchString: string;
}

export const useSearchAnalytics = (params: SearchAnalyticsParams) => {
  const {
    place,
    searchData,
    searchString,
  } = params;

  const {
    searchIsFetching,
    resultsAreNotFounded,
    searchResultsAvailableCount,
  } = searchData;

  const searchAreFetched =  searchResultsAvailableCount > 0 && !searchIsFetching || resultsAreNotFounded;

  // Effect to trigger analytics when search results are fetched
  useEffect(() => {
    if (searchAreFetched) {
      sendSearchFull({
        place,
        searchString,
        searchResultsCount: searchResultsAvailableCount,
      });
    }
  }, [searchAreFetched, searchResultsAvailableCount, searchString, place]);

  // Effect to trigger search start analytics when the user starts typing
  useEffect(() => {
    if (searchString.length >= MIN_SEARCH_LENGTH) {
      sendSearchStart({
        place,
        searchString,
      });
    }
  }, [searchString, place]);
};
