import Spinner from "src/components/Loaders/Spinner/Spinner";
import SearchResults, { SearchResultsProps } from "../SearchResults/SearchResults";
import SearchResultsNotFound from "../SearchResultsNotFound/SearchResultsNotFound";
import { styles } from "./SearchResultsWrapper.styles";

export interface SearchResultsWrapperProps {
  isVisible: boolean;
  loaderIsVisible: boolean;
  resultsAreNotFounded: boolean;
  resultsAreVisible: boolean;
  searchString: string;
  searchResultsProps: SearchResultsProps;
}

const SearchResultsWrapper = (props: SearchResultsWrapperProps) => {
  const {
    isVisible,
    loaderIsVisible,
    resultsAreNotFounded,
    resultsAreVisible,
    searchString,
    searchResultsProps,
  } = props;

  if (isVisible) {
    return (
      <div
        className="SearchResultsWrapper"
        data-marker="Search Results"
      >
        {loaderIsVisible && (
          <div className="SearchResultsWrapper__spinner">
            <Spinner />
          </div>
        )}

        {resultsAreNotFounded && (
          <SearchResultsNotFound
            searchString={searchString}
          />
        )}

        {resultsAreVisible && (
          <SearchResults {...searchResultsProps} />
        )}

        <style jsx >{styles}</style>
      </div>
    );
  }

  return null;
};

export default SearchResultsWrapper;
