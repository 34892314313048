import cn from 'classnames';
import { Fragment } from "react";
import { useTheme } from 'src/ui-kit/theme';
import IconFont from '../../../../ui-kit/IconFont';
import SearchInput, { SearchBoxViewVariant, SearchInputBaseProps } from '../SearchInput/SearchInput';
import SearchResultsWrapper, { SearchResultsWrapperProps } from '../SearchResultsWrapper/SearchResultsWrapper';
import { SearchBoxStyled } from "./SearchBox.styles";
import SearchBoxOverlay from './SearchBoxOverlay';

export interface ClearIconProps {
  isVisible: boolean;
  onClick(): void;
}

export interface SearchBoxProps {
  variant: SearchBoxViewVariant;

  searchIsOpened: boolean;
  searchInputProps: SearchInputBaseProps;
  searchResultsWrapperProps: SearchResultsWrapperProps;
  clearIconProps: ClearIconProps;

  onLoupeIconClick(): void;
  closeSearch(): void;
}

const SearchBox = (props: SearchBoxProps) => {
  const theme = useTheme();

  const {
    variant,
    searchIsOpened,
    searchInputProps,
    searchResultsWrapperProps,
    clearIconProps,

    closeSearch,
    onLoupeIconClick,
  } = props;

  const isMobileView = variant === 'mobile';

  return (
    <SearchBoxStyled
      className={`SearchBox SearchBox_${variant}`}
      data-testid="searchBox"
      data-marker="Search Box"
      onMouseDown={e => e.stopPropagation()}
      theme={theme}
    >
      <SearchInput {...searchInputProps} variant={variant}  />

      {searchIsOpened &&
        <Fragment>
          <SearchBoxOverlay
            variant={variant}
            onClick={closeSearch}
          />
          <span
            className="SearchBox__icon SearchBox__back"
            data-marker="Search Back"
            onClick={closeSearch}
          >
            <IconFont name='arrow-back' />
          </span>
        </Fragment>
      }

      <div className={cn('SearchBox__icons', {
          'SearchBox__icons_mobileLargeHeight': isMobileView,
        })}
      >
        {clearIconProps.isVisible && (
          <div
            className='SearchBox__icon SearchBox__clearSearch'
            data-testid="clear-search-button"
            data-marker="Clear search"
            onClick={clearIconProps.onClick}
            role="button"
            tabIndex={0}
          >
            <IconFont name='close' />
          </div>
        )}

        <div
          onClick={onLoupeIconClick}
          className='SearchBox__icon'
          data-testid="loupe"
          data-marker="Loupe"
          role="button"
          tabIndex={0}
        >
          <IconFont name='loupe' />
        </div>
      </div>

      <div className="SearchBox__results">
        <SearchResultsWrapper {...searchResultsWrapperProps} />
      </div>
    </SearchBoxStyled>
  );
};

export default SearchBox;
