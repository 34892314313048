import styled from '@emotion/styled';
import { phone_tablet } from 'src/styles/media';
import typography from 'src/styles/typography';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const SearchInputStyled = styled('input', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
  display: block;
  width: 100%;
  height: 40px;
  outline: 0 none;
  -webkit-appearance: none;
  padding: 10px 78px 10px 16px;
  border-radius: 3px;
  border: 1px solid ${theme.base.color.gray300};
  background: ${theme.base.color.white};
  color: ${theme.base.color.dark};
  position: relative;
  z-index: 4;

  &:focus {
    border-color: ${theme.base.color.gray500};
    outline: none;
  }

    /* clears the 'X' from Internet Explorer */
  &::-ms-clear {  display: none; width : 0; height: 0; }
  &::-ms-reveal {  display: none; width : 0; height: 0; }

  /* clears the 'X' from Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }

  &::placeholder {
    font-weight: 400;
    color: ${theme.base.color.gray500};
  }

  @media ${phone_tablet} {
    &.SearchInput_withMobile {
      ${typography.h5}
      outline: 0 none;
      -webkit-appearance: none;
      height: 56px;
      padding: 16px 88px 16px 48px;
    }
  }
`);
