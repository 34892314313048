export type SearchPlace = 'catalog' | 'landing';

export const sendOpenSearch = (place: SearchPlace) => {
  window.dataLayer.push({
    event: "search_open",
    place,
  });
};

interface SendSearchStartParams {
  place: SearchPlace;
  searchString: string;
}

export const sendSearchStart = (params: SendSearchStartParams) => {
  const { place, searchString } = params;

  window.dataLayer.push({
    event: "search_start",
    search_term : searchString,
    place,
  });
};

interface SendSearchFullParams {
  place: SearchPlace;
  searchString: string;
  searchResultsCount: number;
}

export const sendSearchFull = (params: SendSearchFullParams) => {
  const { searchResultsCount, place, searchString } = params;

  window.dataLayer.push({
    event: "search_full",
    search_term : searchString,
    search_result: searchResultsCount,
    place,
  });
};
