import { getDataFromResponse } from "src/utils/api/getDataFromResponse";
import useSWR from "swr";
import { fetcher } from "../../../api/fetch";
import { CatalogProductBase, SearchCategoryResultDto, SearchResponseDto } from "../../../api/types";
import { MIN_SEARCH_LENGTH } from "../../../data/constants";

const emptyArray = [];

interface SearchParams {
  searchValue: string;
  language: string;
  perPage?: number;
  page?: number;
  sort_by?: string;
}

const swrFetcher = async ([url, language]: string[]) => {
  const res = await fetcher<SearchResponseDto>(url, { language });
  return getDataFromResponse(res, {} as SearchResponseDto) ;
};

export interface SearchData {
  resultsAreNotFounded: boolean;
  searchIsFetching: boolean;
  searchResultsAvailableCount: number;
  productsResults: CatalogProductBase[];
  searchCategories: SearchCategoryResultDto[];
}

const useSearch = (params: SearchParams): SearchData => {
  const {
    searchValue,
    language,
    perPage = 10,
    page,
    sort_by,
  } = params;
  const q = encodeURIComponent(searchValue);

  const validSearchValue = searchValue.length >= MIN_SEARCH_LENGTH;
  const sortByQuery = sort_by ? `&sort=${sort_by}` : '&sort=relevance_desc';
  const pageQuery = page ? `&page=${page}` : '';
  const perPageQuery =`&per_page=${perPage}`;

  const url = validSearchValue
    ? `/uber_catalog/products/search/?q=${q}${perPageQuery}${pageQuery}${sortByQuery}`
    : null;

  const {
    data,
    error: swrError,
  } = useSWR<SearchResponseDto>(
    validSearchValue ? [url, language] : null,
    swrFetcher,
  );

  const searchIsFetching = validSearchValue && !data && !swrError;
  const productsResults = (data && data.results) || emptyArray;
  const searchResultsAvailableCount = data?.count_available || 0;
  const searchCategories = (data && data.category_results) || emptyArray;
  const resultsAreNotFounded = validSearchValue && !!data && !productsResults.length && !searchCategories.length;

  return {
    searchIsFetching,
    productsResults,
    searchResultsAvailableCount,
    searchCategories,
    resultsAreNotFounded,
  };
};

export default useSearch;
