import Link from 'next/link';
import React, { Fragment } from 'react';
import { SearchCategoryResultDto } from 'src/api/types';
import GoodsNumber from 'src/components/GoodsNumber/GoodsNumber';
import { getCategoriesUrl } from 'src/modules/ubercatalog';
import useLang from 'src/services/language/useLang';
import useLocalize from 'src/services/localize/useLocalize';
import { useTheme } from 'src/ui-kit/theme';
import Carousel from '../Carousel/Carousel';
import { SearchResultsStyled } from './SearchResults.styles';
import SearchResultsProductRow, { SearchResultsProductRowProps } from './SearchResultsProductRow';

export type SearchResultProduct = Omit<SearchResultsProductRowProps, 'onClick'>

export interface SearchResultsProps {
  products: SearchResultProduct[];
  count: number;
  categories: SearchCategoryResultDto[];
  searchString: string;
  onViewAllClick: () => void;
  onProductClick: () => void;
  onCategoryClick: (categoryId: string) => void;
}

const SearchResults = (props: SearchResultsProps) => {
  const {
    products,
    count,
    categories,
    searchString,
    onViewAllClick,
    onCategoryClick,
    onProductClick,
  } = props;

  const localize = useLocalize();
  const theme = useTheme();
  const language = useLang();
  const handleViewAll = (event: React.MouseEvent) => {
    event.stopPropagation();
    onViewAllClick();
  };

  const hasProducts = !!products.length;
  const hasCategories = !!categories.length;

  return (
    <SearchResultsStyled
      className='SearchResults'
      theme={theme}
    >
      {hasCategories &&
        <Fragment>
          <span className='SearchResults__productsListTitle'>
            {localize('search.categories')}
          </span>

          <Carousel
            dataMarker='Categories Carousel'
          >
            {
              categories.map(({ slug, name }, index) => {
                const encodedSearchQuery = encodeURIComponent(searchString);
                const url = getCategoriesUrl({
                  lang: language,
                  categoryId: slug,
                  query: `?q=${encodedSearchQuery}`,
                });

                return (
                  <Link key={`${slug}-search-category`} href={url} legacyBehavior>
                    <a
                      className={'SearchResults__categoriesListItem'}
                      onClick={() => onCategoryClick(slug)}
                      data-marker="Category"
                      data-testid={`category_${index}`}
                    >
                      {name || slug}
                    </a>
                  </Link>
                );
              })
            }
          </Carousel>
        </Fragment>
      }

      {hasProducts && (
        <Fragment>
          {hasCategories && (
            <div className='SearchResults__separator' />
          )}

          <span className='SearchResults__productsListTitle'>
            {localize('products.title')}
          </span>

          <ul
            className='SearchResults__productsList'
            data-marker='Products List'
          >
            {products.map(product => (
              <SearchResultsProductRow
                key={`${product.url}-search-item`}
                {...product}
                onClick={onProductClick}
              />
            ))}
          </ul>
        </Fragment>
      )}

      {count > 10 &&
        <button
          className={'SearchResults__viewAll'}
          onClick={handleViewAll}
          data-testid='viewAll'
          data-marker='View All'
        >
          {localize('general.show-all')}
          {' '}
          <GoodsNumber number={count} />
        </button>
      }
    </SearchResultsStyled>
  );
};

export default SearchResults;
