import { useTheme } from "src/ui-kit/theme";
import { SearchBoxViewVariant } from "../SearchInput/SearchInput";
import { SearchBoxOverlayStyled } from './SearchBoxOverlay.styles';

interface Props {
  onClick(): void;
  variant: SearchBoxViewVariant;
}

const SearchBoxOverlay = ({ onClick, variant }: Props) => {
  const theme = useTheme();

  return (
    <SearchBoxOverlayStyled
      theme={theme}
      className={`SearchBoxOverlay SearchBoxOverlay_${variant}`}
      onClick={onClick}
      data-testid="overlay"
      data-marker="Overlay"
    />
  );
};

export default SearchBoxOverlay;
