import { phone_tablet } from "src/styles/media";
import css from "styled-jsx/css";

export const styles = css`
  .SearchResultsWrapper__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 225px;
  }
  @media ${phone_tablet} {
    .SearchResultsWrapper__spinner {
      height: 140px;
    }
  }
`;
